import styled, { css } from 'styled-components'

import { Box, colors, DonutChart, DonutChartDatum, media as mediaCss, Text } from '@cutover/react-ui'
import { DashboardMediaType, GenericWidgetProps, TaskWastageComponent } from '../../types'
import { MrdDashboardWidget } from '../../account/mrd-dashboard-widget'

export type TaskWastageWidgetProps = {
  media: DashboardMediaType
  data: TaskWastageComponent
}

export const TaskWastageWidget = ({ data, media }: TaskWastageWidgetProps) => {
  const donutData: DonutChartDatum[] = data.values.map(value => ({
    id: value.name,
    label: `${value.count} ${value.name}`,
    name: value.name,
    value: value.count,
    color: value.color
  }))

  const isEmpty = donutData.every(item => item.value === 0)

  const fullWidth = data.width === 100 || !data.width

  return (
    <MrdDashboardWidget title={data.name} fullWidth={fullWidth}>
      {data.errors && (
        <Text color="error" size="small">
          {data.errors}
        </Text>
      )}
      <ContentContainer media={media}>
        <DonutContainer>
          {isEmpty ? (
            <EmptyChart data={donutData} height={140} />
          ) : (
            <DonutChart data={donutData} withKey centerValue={`${data.total}`} height={140} />
          )}
        </DonutContainer>
      </ContentContainer>
    </MrdDashboardWidget>
  )
}

const EmptyChart = ({ data, height }: { data: DonutChartDatum[]; height: number }) => {
  const emptyData = data.map(d => ({
    ...d,
    color: colors.primaryGreyHoverBackgroundColor,
    value: 100 / data.length
  }))

  // this is dependent on the donut chart layer order
  return (
    <>
      <Box
        css={`
          position: absolute;
          svg > g > g:first-child {
            visibility: hidden;
          }
        `}
      >
        <DonutChart data={data} withKey height={height} />
      </Box>
      <Box
        css={`
          position: absolute;
          svg > g > g:nth-child(2) {
            visibility: hidden;
          }
        `}
      >
        <DonutChart data={emptyData} segmentGap={3} withKey centerValue="0" height={height} />
      </Box>
    </>
  )
}
const ContentContainer = styled(Box)<{ media: GenericWidgetProps['media'] }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${({ media }) => (media === 'email' ? '10px' : '0')};
  min-width: ${({ media }) => (media === 'email' ? 'auto' : '310px')};
  width: ${({ media }) => (media === 'email' ? '100%' : '49%')};

  ${mediaCss.sm(css`
    flex-direction: column;
    width: 100%;
    min-width: auto;
  `)}
`

const DonutContainer = styled(Box)<{ media?: GenericWidgetProps['media'] }>`
  display: flex;
  position: relative;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  min-height: 200px;
  align-items: center;

  ${mediaCss.sm(css`
    width: 100%;
  `)}
`
