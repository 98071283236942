import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Box, breakpoints } from '@cutover/react-ui'
import {
  CommentsWidget,
  CompletedLiveRunsWidget,
  ContentWidget,
  CustomFieldSummaryWidget,
  MonthlyActiveUsersWidget,
  RunbookAverageDurationWidget,
  RunbooksCreatedWidget,
  TaskCompletionUserCountWidget,
  TasksCompletedWidget
} from './widgets'
import { TaskCompletionOverTimeWidget } from './widgets/runbook/task-completion-over-time/task-completion-over-time-widget'
import { DashboardComponent, DashboardComponentCollection, DashboardMediaType, DashboardWidgets } from './widgets/types'
import {
  // conflicting names between SRD and MRD Rag Status components
  AttributeCompletionWidget,
  AverageRunbookDurationOverTimeWidget,
  AverageRunbookDurationWidget,
  CompletionByStageWidget,
  LatenessWidget,
  LongestAverageTaskDurationSingleWidget,
  LongestAverageTaskDurationsWidget,
  LongestDurationRunbooksWidget,
  ParticularTaskAverageDurationWidget,
  RagStatusWidget,
  RehearsalCountWidget,
  RunbookAdminWidget,
  RunbookCompletionWidget,
  RunbookCountByTypeOverTimeWidget,
  RunbookListWidget,
  RunbookMatrixWidget,
  RunbookRagStatusWidget,
  RunbookSizeSummaryWidget,
  RunbookSplitWidget,
  RunbookStageSummaryWidget,
  StreamSummaryWidget,
  TaskCompletionSummaryWidget,
  TaskListWidget,
  TaskSplitWidget,
  TaskStageSummaryWidget,
  VolumeTableWidget
} from 'main/components/dashboards/widgets'
import { useUserWebsocket } from 'main/services/hooks'
import { TaskWastageWidget } from './widgets/runbook/task-wastage/task-wastage-widget'

type DasboardWidgetCollectionProps = {
  components: DashboardComponentCollection
  media: DashboardMediaType
  requestId?: string
}

const TestComponent = (component: any) => (
  <div style={{ border: '1px solid green' }}>
    <pre>{JSON.stringify(component)}</pre>
  </div>
)

const ACCOUNT_DASHBOARD_WIDGETS: Record<string, DashboardWidgets> = {
  // Analytics
  completed_live_runs: CompletedLiveRunsWidget,
  monthly_active_users: MonthlyActiveUsersWidget,
  runbook_template_creation_counts: RunbooksCreatedWidget,
  runbook_average_duration: RunbookAverageDurationWidget,
  tasks_completed_count: TasksCompletedWidget,
  user_count_task_completion: TaskCompletionUserCountWidget,
  // MRD
  average_runbook_duration: AverageRunbookDurationWidget,
  average_runbook_duration_over_time: AverageRunbookDurationOverTimeWidget,
  lateness: LatenessWidget,
  longest_average_task_duration_single: LongestAverageTaskDurationSingleWidget,
  longest_average_task_durations: LongestAverageTaskDurationsWidget,
  longest_duration_runbooks: LongestDurationRunbooksWidget,
  particular_task_average_duration: ParticularTaskAverageDurationWidget,
  rag_status: RagStatusWidget,
  rehearsal_count: RehearsalCountWidget,
  runbook_attribute_completion: RunbookCompletionWidget,
  runbook_attribute_split: RunbookSplitWidget,
  runbook_count_by_type_over_time: RunbookCountByTypeOverTimeWidget,
  runbook_list: RunbookListWidget,
  runbook_matrix: RunbookMatrixWidget,
  runbook_size: RunbookSizeSummaryWidget,
  runbook_stage: RunbookStageSummaryWidget,
  stream_completion: AttributeCompletionWidget,
  task_attribute_split: TaskSplitWidget,
  task_stage: TaskStageSummaryWidget,
  task_attribute_completion: AttributeCompletionWidget,
  volume: VolumeTableWidget,
  // test for back end specs
  test: TestComponent
}

const RUNBOOK_DASHBOARD_WIDGETS: Record<string, DashboardWidgets> = {
  // SRD
  burn_chart: TaskCompletionOverTimeWidget,
  comments: CommentsWidget,
  header: TaskCompletionSummaryWidget,
  status: RunbookRagStatusWidget,
  stage_summary: CompletionByStageWidget,
  streams: StreamSummaryWidget,
  task_list: TaskListWidget,
  custom_field_donut: CustomFieldSummaryWidget,
  admins: RunbookAdminWidget,
  content: ContentWidget,
  // PIR
  task_wastage: TaskWastageWidget,
  // test for back end specs
  test: TestComponent
}

// merge the current array of components
const mergeComponentLists = (currentComponentsState: DashboardComponent[], newComponents: DashboardComponent[]) => {
  return currentComponentsState.map((component: DashboardComponent) => {
    const newComponent = newComponents.find((newComponent: DashboardComponent) => newComponent.id === component.id)
    return newComponent ? newComponent : component
  })
}

export const StatefulAccountWidgetCollection = ({ components, media, requestId }: DasboardWidgetCollectionProps) => {
  const [componentsState, setComponentsState] = useState<DashboardComponent[]>(components)

  const { listen } = useUserWebsocket()
  useEffect(() => {
    listen(data => handleWebsocketResponse(data))
  })

  const handleWebsocketResponse = (data: any) => {
    if (data?.meta?.headers?.request_method === 'dashboard_results') {
      const { request_id, components } = data.messages[0]
      if (!requestId || request_id == requestId) {
        const mergedComponents = mergeComponentLists(componentsState, components)
        setComponentsState(mergedComponents)
      } else {
        console.log('dashboard-widget-collection handleWebsocketResponse ignored stale request', requestId, request_id)
      }
    }
  }

  return componentsState ? (
    <WidgetGrid direction="row">
      {componentsState.map((component, i) => {
        const { type, id } = component
        const Component = ACCOUNT_DASHBOARD_WIDGETS[type]

        return Component ? <Component key={id} media={media} data={component} /> : <NoComponentMessage key={id || i} />
      })}
    </WidgetGrid>
  ) : (
    <NoComponentMessage />
  )
}

// (original) without the useState/useEffect complexity that may cause issues for SSR rendering:
export const AccountWidgetCollection = ({ components, media }: DasboardWidgetCollectionProps) => {
  return components ? (
    <WidgetGrid direction="row">
      {components.map((component, i) => {
        const { type, id } = component
        const Component = ACCOUNT_DASHBOARD_WIDGETS[type]

        return Component ? <Component key={id} media={media} data={component} /> : <NoComponentMessage key={id || i} />
      })}
    </WidgetGrid>
  ) : (
    <NoComponentMessage />
  )
}

export const RunbookWidgetCollection = ({ components, media }: DasboardWidgetCollectionProps) => {
  return components && components.length ? (
    <WidgetGrid direction="row">
      {components.map((component, i) => {
        const { type, id } = component
        const Component = RUNBOOK_DASHBOARD_WIDGETS[type]

        return Component ? <Component key={id} media={media} data={component} /> : <NoComponentMessage key={id || i} />
      })}
    </WidgetGrid>
  ) : (
    <NoComponentMessage />
  )
}

const NoComponentMessage = () => {
  // TODO: future improvement on no component message (e.g. please add components via settings)
  return <></>
}

const WidgetGrid = styled(Box)`
  flex-wrap: wrap;
  @media ${breakpoints.sm} {
    padding: 0;
    padding-top: 12px;
  }
`
