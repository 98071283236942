import { ReactNode, SyntheticEvent } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

import { resolveColor, themeColor } from '../theme'
import { TaskItemDateData } from './task-item'
import { Box } from '../layout'
import { Text, TextProps } from '../typography'

type TaskItemLayoutProps = {
  'data-testid': string
  onClick?: (e: SyntheticEvent) => void
  onFocus?: (e: SyntheticEvent) => void
  onDragOver?: (e: React.DragEvent) => void
  onDragEnter?: (e: React.DragEvent) => void
  onDragLeave?: (e: React.DragEvent) => void
  onDrop?: (e: React.DragEvent) => void
  activeTimezone?: string | null
  draggable?: boolean
  highlight?: boolean
  rtoHighlight?: boolean
  loading?: boolean
  disabled?: boolean
  editable?: boolean
  isDraggedOver?: boolean
  showBorderTop?: boolean
  hideStageIcon?: boolean
  prefixContent?: ReactNode
  dateData?: TaskItemDateData
  isLateStartFixed?: boolean
  selected?: boolean
  icon: ReactNode
  mainContentPrefix?: ReactNode
  mainContent: ReactNode
  mainContentSubLine?: ReactNode
  integrationStatus?: ReactNode
  hasIntegrationError?: boolean
  endContent?: ReactNode
  suffixContent?: ReactNode
}

export const TaskItemLayout = ({
  'data-testid': dataTestId,
  onClick,
  onFocus,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  selected,
  activeTimezone,
  draggable,
  editable,
  highlight,
  rtoHighlight,
  isDraggedOver,
  loading,
  disabled,
  showBorderTop,
  prefixContent,
  dateData,
  isLateStartFixed,
  icon,
  mainContentPrefix,
  mainContent,
  mainContentSubLine,
  integrationStatus,
  hasIntegrationError,
  endContent,
  suffixContent
}: TaskItemLayoutProps) => (
  <TaskItemContainer
    data-testid={dataTestId}
    onClick={onClick}
    onFocus={onFocus}
    onDragOver={onDragOver}
    onDragEnter={onDragEnter}
    onDragLeave={onDragLeave}
    onDrop={onDrop}
    draggable={draggable}
    $loading={loading}
    highlight={highlight}
    rtoHighlight={rtoHighlight}
    disabled={disabled}
    showBorderTop={showBorderTop}
    className={`${editable ? 'editable' : ''} ${isDraggedOver ? 'dragging-over' : ''}`}
  >
    {prefixContent}

    <Box width={activeTimezone ? '140px' : '94px'} flex={false} align="end" data-testid="task-item-date">
      <DateText color={isLateStartFixed ? 'warning' : 'text-light'} $loading={loading}>
        {!selected && typeof isLateStartFixed === 'boolean' && (
          <FixedStartText className="task-item-remove-on-task-hover" late={isLateStartFixed}>
            F
          </FixedStartText>
        )}
        <Text
          css={`
            display: inline-block;
            width: 52px;
            text-align: right;
          `}
        >
          <Text className={`date-day ${dateData?.showDate ? '' : 'hide'}`}>{dateData?.date}</Text>
        </Text>
        <Text
          css={`
            display: inline-block;
            width: 46px;
            text-align: right;
          `}
        >
          <Text className={`date-time ${dateData?.showTime ? '' : 'hide'}`}>{dateData?.time}</Text>
        </Text>
        {activeTimezone && (
          <Text css="display: inline-block; width: 46px; height: 21px; text-align: right; position: relative;">
            <Text className={`date-local-time ${dateData?.showTime ? '' : 'hide'}`}>{dateData?.localTime}</Text>
            {dateData?.localDayDiff && (
              <Text
                className={`task-item-display-on-task-hover ${dateData?.showTime ? '' : 'hide'}`}
                color="text-light"
                size="12px"
                css="display: block; position: absolute; right: 2px; top: 19px;"
              >
                {dateData?.localDayDiff}
              </Text>
            )}
          </Text>
        )}
      </DateText>
    </Box>
    <Box flex={false}>{icon}</Box>
    {mainContentPrefix && <Box flex={false}>{mainContentPrefix}</Box>}
    <Box
      direction="row"
      gap="xxsmall"
      align="center"
      css={`
        flex: 1 1 ${integrationStatus ? (hasIntegrationError ? '35%' : '70%') : '100%'};
        position: relative;
      `}
    >
      {mainContent}
      <Box css="position: absolute; top: 18px;" data-testid="task-content-box">
        {mainContentSubLine}
      </Box>
    </Box>
    {integrationStatus && (
      <Box
        direction="row"
        gap="xsmall"
        align="center"
        css={`
          flex: 1 1 ${hasIntegrationError ? '65%' : '30%'};
          min-width: 160px;
        `}
      >
        {integrationStatus}
      </Box>
    )}
    <Box direction="row" gap="xsmall" align="center" css="flex: 0 0 auto;">
      {endContent}
      <Box
        width="32px"
        height="32px"
        alignSelf="center"
        align="center"
        justify="center"
        flex={false}
        css={`
          .dragging-user .editable & {
            > div {
              opacity: 0.3;
            }
            border-radius: 50%;
            outline: 2px dashed ${themeColor('text-disabled')};
          }
          .dragging-over.editable & {
            outline: 2px dashed ${themeColor('text')};
          }
        `}
      >
        {suffixContent}
      </Box>
    </Box>
  </TaskItemContainer>
)

const TaskItemContainer = styled(Box).attrs(() => ({
  align: 'center',
  direction: 'row',
  gap: '6px',
  height: '52px',
  pad: { right: 'xsmall', top: 'xsmall', bottom: 'xsmall' }
}))<{
  showBorderTop?: boolean
  $loading?: boolean
  highlight?: boolean
  rtoHighlight?: boolean
  disabled?: boolean
  hideStageIcon?: boolean
}>`
  background-color: ${({ highlight, rtoHighlight, theme }) =>
    (highlight || rtoHighlight) && resolveColor(rtoHighlight ? 'star-bg' : 'primary-bg', theme)} !important;
  border-top: ${({ showBorderTop, theme }) => showBorderTop && `1px solid ${resolveColor('bg-2', theme)}`};
  margin-right: 16px;
  opacity: ${({ $loading, disabled }) => ($loading || disabled) && 0.5};
  outline: none;
  flex: 0 0 auto;

  .task-item-display-on-task-hover {
    display: none;
  }

  .task-item-remove-on-task-hover {
    display: block;
  }

  &:hover {
    background-color: ${({ theme }) => resolveColor('bg-1', theme)};

    .task-item-bg {
      background-color: ${({ theme }) => resolveColor('bg-1', theme)};
    }

    .task-item-display-on-task-hover {
      display: flex;
    }

    .task-item-remove-on-task-hover {
      display: none;
    }
  }
`

const flashUpdating = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
`

const FixedStartText = styled(Text)<TextProps & { late: boolean }>`
  font-weight: bold;
  margin-left: 7px;
  padding-right: 6px;
  position: absolute;
  left: 8px;
  align-self: center;
  color: ${({ late }) => (late ? 'warning' : 'black')};
`

const DateText = styled(Text)<TextProps & { $loading?: boolean }>`
  white-space: nowrap;
  .hide {
    display: none !important;
  }
  ${({ $loading }) =>
    $loading &&
    css`
      animation: 0.6s linear infinite ${flashUpdating};
    `}
`
